.horizontal-content {
  display: flex;

  &--tiny {
    gap: $tiny-gap;
  }

  &--smaller {
    gap: $smaller-gap;
  }

  &--small {
    gap: $small-gap;
  }

  &--content-padding {
    gap: $content-padding;
  }

  &--large {
    gap: $large-gap;
  }

  &--xlarge {
    gap: $xlarge-gap;
  }

  &--stretch-wrap {
    align-items: stretch;
    flex-wrap: wrap;
  }

  &--desktop-no-wrap {
    @include lg {
      flex-wrap: nowrap;
    }
  }
}
