.body {
  &--style-v2, &--style-v2-faded-1, &--style-v3, &--style-v3-compact {
    @include body-text;

    p, blockquote {
      @include no-vertical-margin-around;
    }

    ul {
      margin-top: 0;
    }

    img {
      max-width: 100%;
    }

    blockquote {
      background-color: $gray;
      border-radius: $border-radius;
      margin-left: $content-padding;
      margin-right: $content-padding;
      padding: $small-gap $content-padding;

      @include md {
        padding: $content-padding (2 * $content-padding);
      }
    }

    pre {
      overflow-x: auto;
    }

    code {
      background-color: $off-black;
      color: $orange;
      border-radius: $border-radius;
      padding: $tiny-gap;
    }
  }

  &--style-v2-faded-1 {
    color: $faded-white-1;
  }

  &--style-v3, &--style-v3-compact {
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.55);
    color: $white-800;
    line-height: 140%;
    font-size: $smaller-body-font-size;

    @include sm {
      font-size: $body-font-size;
      line-height: 150%;
    }

    .enhanced-markdown-image, .optimized-image {
      img {
        display: block;
        max-width: 100%;
        filter: brightness(.9) contrast(1.1);
        margin-left: 0.5em;
        margin-right: 0.5em;
        border-radius: $border-radius * 2;
      }
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin-bottom: $small-gap;
      margin-inline-start: $small-gap;

      @include md {
        margin-inline-start: 2rem;
      }
    }

    pre {
      background-color: $closer-background;
      padding: 1em;
      border-radius: $border-radius;
      overflow-x: auto;
      font-size: pxToRem(14);
      line-height: 1.5;
    }

    code {
      background-color: $closer-background;
      color: $faded-white-2;
      border-radius: $border-radius;
      padding: $tiny-gap;
    }

    pre > code {
      background-color: unset;
      padding: 0;
    }

    ul, ol {
      padding-inline-start: 1.5rem;

      li {
        line-height: 2rem;
      }
    }

    details > summary {
      cursor: pointer;
      user-select: none;
      margin-block-start: 0.25rem;
      margin-block-end: 0.25rem;
    }

    .react-tile {
      margin-block: 1em;
    }

    .react-table__wrapper + p, .react-table__wrapper + details {
      margin-top: 2rem;
    }
  }

  &--style-v3-compact {
    font-size: $smaller-body-font-size;
  }

  &--style-article {
    @include body-text;
    color: $faded-white-3;

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    .enhanced-markdown-image, .optimized-image {
      img {
        display: block;
        max-width: 100%;
        filter: brightness(.9) contrast(1.1);
        margin-left: 0.5em;
        margin-right: 0.5em;
        border-radius: $border-radius * 2;
      }
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin-bottom: $small-gap;
      margin-inline-start: $small-gap;

      @include md {
        margin-inline-start: 2rem;
      }
    }

    pre {
      background-color: $closer-background;
      padding: 1em;
      border-radius: $border-radius;
      overflow-x: auto;
      font-size: pxToRem(14);
      line-height: 1.5;
    }

    code {
      background-color: $closer-background;
      color: $faded-white-2;
      border-radius: $border-radius;
      padding: $tiny-gap;
    }

    pre > code {
      background-color: unset;
      padding: 0;
    }

    a {
      color: $link-muted-blue;
    }

    ul, ol {
      padding-inline-start: 1.5rem;

      li {
        line-height: 2rem;
      }
    }

    details > summary {
      cursor: pointer;
      user-select: none;
    }

    .react-tile {
      margin-block: 1em;
    }
  }
}

