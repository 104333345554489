.report-assistant {
  position: relative;
  height: 100%;

  &__expander {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100001;
    background: transparent;
    border: 0;
    filter: brightness(1);
    transition: filter 0.1s ease-in-out;

    &:hover {
      filter: brightness(1.15);
    }

    &--above-corner-video {
      bottom: 280px;
    }

    img {
      border-radius: 100%;
      width: 32px;
      height: 32px;
      cursor: pointer;
      border: 2px solid $level-2-border;
      transform: scale(1);
      transition: transform 0.1s ease-in-out, filter 0.1s ease-in-out, border 0.1s ease-in-out;

      @include md {
        width: 64px;
        height: 64px;
      }

      &:hover {
        animation: spin 0.8s ease-in-out 1;
        transform: scale(1.05);
        border: 2px solid $level-border-active;
      }
    }
  }

  &__expander-speech-bubble {
    font-size: pxToRem(14);
    position: absolute;
    bottom: 6px;
    right: 66px;
    width: 160px;
    border: 1px solid $level-1-border;
    background: $level-1-bg;
    border-radius: $border-radius;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: $white-800;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 70%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: $level-1-border;
      border-right: 0;
      border-top: 0;
      transform: rotate(270deg);
      margin-top: -10px;
      margin-right: -20px;
    }
  }

  &__expander-speech-bubble-close-button {
    position: absolute;
    top: 2px;
    right: -6px;
    padding: $tiny-gap;
    background: transparent;
    border: 0;
    filter: brightness(1);
    transition: filter 0.1s ease-in-out;
    font-size: pxToRem(12);

    &:hover {
      filter: brightness(1.15);
    }
  }

  &__container {
    position: fixed;
    z-index: 100000;
    top: $header-mobile-height;
    right: calc(-700px - $small-gap * 2);
    width: 700px;
    max-width: calc(100vw - $small-gap * 2 - 18px);
    padding: $content-padding $small-gap;
    height: calc(100% - 2 * $content-padding);
    max-height: calc(100vh - $header-mobile-height - 2 * $content-padding - 24px);
    overflow-y: auto;
    @include restyled-scroll-bar;
    background: $level-1-bg;
    transition: right 0.2s ease-in-out;

    @include md {
      top: var(--header-desktop-height);
      max-height: calc(100vh - var(--header-desktop-height) - 2 * $content-padding - 12px);
    }

    &--expanded {
      right: 0;
      border-left: 1px solid $level-2-border;
      border-bottom: 1px solid $level-2-border;
    }
  }

  &__greeting-details {
    font-size: pxToRem(14);
    color: $white-700;
    border: 1px solid $level-1-border;
    background: $level-1-bg;
    border-radius: $border-radius;
    padding: $smaller-gap $small-gap;
    line-height: 130%;

    summary {
      cursor: pointer;
      font-size: pxToRem(15);
      color: $white-800;
    }

    div {
      margin-top: $smaller-gap;
    }
  }

  &__example-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $tiny-gap;
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__remaining-credits {
    padding: 0 $tiny-gap;
    display: flex;
    gap: $smaller-gap;
    align-items: center;
  }

  &__remaining-credits-label {
    font-size: pxToRem(16);
    color: $white-700;
  }

  &__remaining-credits-tooltip {
    font-size: pxToRem(18);
    color: $white-800;
  }

  &__close-button {
    position: absolute;
    top: 19px;
    right: 10px;
    padding: $tiny-gap $small-gap;
  }

  &__loading-box {
    width: 100%;
    height: 400px !important;
    border: 1px solid $level-1-border;
  }

  &__response-container {
    position: relative;
    padding-top: 24px;
  }

  &__code-container {
    position: relative;

    pre {
      padding: 0 !important;
      padding-bottom: 1em !important;
    }
  }

  &__copy-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__rating-button {
    &--active {
      background: $level-2-bg;
      border: $level-2-border;
    }
  }

  &__rating-label {
    margin-left: $smaller-gap;
    font-size: pxToRem(16);
    color: $white-600;
  }

  &__alternatives {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $tiny-gap;
  }

  &__alternatives-label {
    font-size: pxToRem(15);
    color: $white-800;
  }

  &__alternative-button {
    min-width: 50px;

    &--good {
      border: 1px solid $blue;
    }
  }

  &__alternatives-tooltip {
    font-size: pxToRem(18);
    color: $white-800;
    margin-left: $tiny-gap;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  15% {
    transform: rotate(-20deg) scale(0.95);
  }
  70% {
    transform: rotate(380deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1.05);
  }
}
