.form-element {
  &__label {
    label {
      font-weight: normal;

      * {
        font-size: $smaller-font-size;
        font-weight: normal;
      }
    }
  }

  &__control {}

  &--label-top {
    margin-bottom: 1rem;

    .form-element{
      &__label{
        margin-bottom: 0.33rem;
      }
    }
  }

  &--label-left {
    margin-bottom: 1rem;

    .form-element{
      &__label{
        display: inline;
        margin-right: 0.5rem;
        margin-bottom: 0.33rem;
      }
      &__control{
         display: inline;
         margin-bottom: 0.33rem;
       }
    }
  }

  &--constrain-width {
    max-width: 100%;
    @include sm {
      max-width: 16rem;
    }
  }
}
