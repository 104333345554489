.bars {
  display: flex;
  width: 100%;

  &__bar {
    &:not(:first-child) {
      border-left: 1px solid #000;
    }
  }
}
