.a52596fdd-sticky-footer {
  pointer-events: none;
  position: sticky;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
  grid-template-columns: 1fr 0 minmax(0, 970px) 0 1fr;
  margin: 0 auto;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  @media (min-width: 1090px) {
    display: grid;
  }


  &__children {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }

  &--with-only-right-child {
    grid-template-columns: 1fr 0 minmax(320px, 970px) 1fr auto;
    padding-right: 20px;
  }

  &--with-corner-video {
    @media (min-width: 1350px) {
      grid-template-columns: 1fr 0 minmax(0, 970px) 0 minmax(var(--right-cell-min-width, 0), 1fr);
    }
  }

  &--playwire {
    --right-cell-min-width: 250px;
  }
}


