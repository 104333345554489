.tooltip {
  &__trigger {
    appearance: none;
    background: none;
    padding: 0;
    border: none;

    &button {
      cursor: pointer;
    }
  }

  &__body {
    margin-top: $smaller-gap;
    color: $white-800;
    border-radius: $border-radius;
    display: inline-block;
    padding: 4px 18px 6px;
    max-width: 1024px;
    font-size: 1rem;
    line-height: 1.6875rem;
    z-index: 2;
    pointer-events: none;

    &--level-0 {
      background-color: $level-0-bg-no-gradient;
      border: 1px solid $level-0-border;
    }

    &--level-1 {
      background-color: $level-1-bg-no-gradient;
      border: 1px solid $level-1-border;
    }

    &--level-2 {
      background-color: $level-2-bg-no-gradient;
      border: 1px solid $level-2-border;
    }
  }

  &__arrow {
    position: absolute;
    background: black;
    border: 1px solid $level-1-border;
    width: 1rem;
    height: 1rem;

    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0 L100 100 L0 100 z' fill='%23ffffff'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0 L100 100 L0 100 z' fill='%23ffffff'/%3E%3C/svg%3E");
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;

    &[data-side='top'] {
      transform: rotate(135deg);
    }

    &[data-side='bottom'] {
      transform: rotate(315deg);
    }

    &[data-side='left'] {
      transform: rotate(45deg);
    }

    &[data-side='right'] {
      transform: rotate(225deg);
    }
  }
}
