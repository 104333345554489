.bar {
  &__big-bar {
    width: 100%;
    position: relative;
  }

  &__small-bar {
    position: absolute;
    bottom: 0;
    height: 1px;
  }

  &__container {
    position: relative;
  }

  &__darken {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
