$archon: true;
@use "../../resources/sass/variables";
@use "../../resources/sass/breakpoints";

@import "./material-design-iconic-font";

@font-face {
  font-family: 'ralewayregular';
  src: url('https://assets2.rpglogs.com/fonts/Raleway-Regular-webfont.eot');
  src: url('https://assets2.rpglogs.com/fonts/Raleway-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('https://assets2.rpglogs.com/fonts/Raleway-Regular-webfont.woff') format('woff'),
  url('https://assets2.rpglogs.com/fonts/Raleway-Regular-webfont.ttf') format('truetype'),
  url('https://assets2.rpglogs.com/fonts/Raleway-Regular-webfont.svg#ralewayregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --archon-header-desktop-height: 55px;
  --archon-header-mobile-height: 49px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: variables.$cms-background-color;
}
::-webkit-scrollbar-thumb {
  background-color: variables.$light-gray;
  border-radius: 2px;
}

html, body, #__next {
  height: 100%;
  background-color: variables.$cms-background-color;
  scroll-snap-type: y proximity;
}

body {
  padding: 0 !important;

  #__next {
    display: flex;
    flex-direction: column;
  }

  &.archon .archon-report-url {
    display: flex; 
    align-items: center;
  }
}

.builds-page {
  &__content {
    span.hide-on-compact {
      display: block;
      margin-block-start: 1rem;
    }

    &--compact {
      .heading:has(+ span.hide-on-compact) {
        margin-bottom: 0;
      }

      div.hide-on-compact + div {
        margin-top: 0;
      }

      .hide-on-compact {
        display: none;
      }

      .no-top-margin-on-compact {
        margin: 0;
      }
    }
  }
}
