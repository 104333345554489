.menu-select {
  &__control {
    background: $level-1-bg !important;
    color: $off-white !important;
    border: 1px solid $level-1-border !important;
    border-radius: $border-radius !important;
    transition: border 100ms ease-in-out !important;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus,
    &--is-focused {
      border: 1px solid $level-2-border !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &--is-disabled {
      pointer-events: auto !important;
      cursor: not-allowed !important;
      color: $lighter-gray !important;

      &:hover {
        border: 1px solid transparent !important;
      }
    }
  }

  &__value-container {
    justify-content: left;
    padding: unset !important;
    height: 36px;

    &--multi-select {
      height: fit-content;
    }
  }

  &__single-value {
    color: $off-white !important;
    padding: 0.5rem 0.75rem !important;
    @include body-text(true);

    // This sizes the control to be based on the selected label's width
    position: unset !important;
    top: unset !important;
    transform: unset !important;
    text-overflow: unset !important;
    height: pxToRem(29);
    margin-top: pxToRem(-14);
    overflow: unset !important;

    &--is-disabled {
      color: $lighter-gray !important;
    }
  }

  &__multi-value {
    background: $level-2-bg !important;
    @include body-text;

    &__label:hover {
      color: $white-900 !important;
    }

    &__label {
      color: $white-800 !important;
    }

    &__remove:hover {
      background: $level-0-bg !important;
      color: $white !important;
    }

    &--sortable &__label:hover {
      cursor: grab;
    }
  }

  &--multi {
    .menu-select__control {
      padding: $tiny-gap;
    }

    .menu-select__value-container {
      height: fit-content;
    }
  }

  &--async {
    .menu-select__placeholder {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      padding-left: 1rem;
    }

    .menu-select__control {
      background-color: $gray !important;
    }

    .menu-select__value-container {
      flex: unset;
      max-width: 75%;
    }

    .menu-select__input input {
      background-color: unset !important;
      color: $off-white !important;
      padding: 0 $small-gap !important;
      width: auto !important;
      @include body-text(true);
    }

    .menu-select__single-value {
      padding-right: 0 !important;
    }

    .menu-select__single-value ~ div > .menu-select__input input {
      width: 2px !important;
      padding: unset !important;
    }

    .menu-select__dropdown-indicator {
      display: none;
    }

    .menu-select__clear-indicator {
      cursor: pointer;
    }

    // fixes a bug in react-select when you select a dropdown item, blur
    // and focus again. only after an additional blur + focus the cursor shows
    .menu-select__control--is-focused input {
      opacity: 1 !important;
    }
  }


  &--header-search {
    flex-grow: 1;
    z-index: 1;
    position: relative;

    @include md {
      padding-right: 31px;
    }
  }

  &--header-search & {
    &__menu-list {
      scrollbar-width: thin;
      scrollbar-color: $level-2-border $cms-background-color;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: $cms-background-color;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $level-2-border;
        border-radius: 3px;
      }
    }

    &__control {
      background: $level-2-bg !important;
      border-color: $level-2-border !important;
      cursor: text;
      align-items: stretch;
      min-height: 31px !important;
      height: 31px;
      border-radius: 3px !important;
      box-sizing: border-box !important;

      &:hover,
      &:active,
      &:focus-visible,
      &:focus,
      &--is-focused {
        border-color: $level-2-border !important;
      }

      .menu-select__input {
        display: block !important;

        input {
          font-size: $smaller-font-size !important;
          line-height: $smaller-font-size !important;
          color: $white-800 !important;
          width: 100% !important;
          box-sizing: border-box !important;
          padding-top: 1px !important;
        }
      }

      @include md {
        border-radius: 3px 0px 0px 3px !important;
      }
    }

    &__indicators {
      min-width: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    &__clear-indicator {
      display: none;
    }

    &__placeholder {
      left: 1rem;
      padding: 0 !important;
      margin: 0 !important;
      width: calc(100% - 1rem) !important;
    }

    &__value-container {
      max-width: none !important;
      height: 100%;
      flex-grow: 1 !important;
      align-items: flex-start;
      justify-content: stretch;

      > div {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &__menu {
      max-width: 100%;

      @include md {
        max-width: none;
      }
    }
  }

  &__indicator {
    color: $off-white !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    padding-left: 0 !important;
  }

  &__menu {
    border: 1px solid $level-1-border !important;
    border-radius: $border-radius !important;
    margin-top: 1px !important;
    margin-bottom: $tiny-gap !important;

    // Let's the menu grow as wide as the widest option label
    // but still be as thin as the selected option label at a minimum
    min-width: 100% !important;
    width: unset !important;
    z-index: 100 !important;
  }

  &__menu-list {
    background: $level-1-bg;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow-x: hidden;
  }

  &--no-max-height & {
    &__menu-list {
      max-height: calc(90vh - #{$header-mobile-height}) !important;

      @include md {
        max-height: calc(90vh - var(--header-desktop-height)) !important;
      }
    }
  }

  &__option {
    color: $off-white !important;
    @include body-text(true);
    padding: 0.5rem 0.975rem !important;
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus,
    &--is-focused {
      padding: 0.5rem 0.975rem !important;
      background: $level-2-bg !important;
      outline: none;
    }

    &--is-selected {
      background: $level-1-bg !important;

      &:hover {
        background: $level-2-bg !important;
      }
    }
  }

  &--slim {
    zoom: 0.75;
  }
}

.link-select {
  display: table;

  &__single-value,
  & input {
    font-size: $body-font-size !important;
    font-weight: 700 !important;
    color: $link-blue !important;
    margin: 0 !important;
    margin-right: 10px !important;
    max-width: none !important;
    overflow: visible !important;
    top: auto !important;
    transform: none !important;
    position: relative !important;
    transition: color 0.14s ease-out;
  }

  & input {
    background-color: transparent !important;
    caret-color: transparent;
  }

  &--large .link-select__single-value {
    font-weight: 900 !important;
    font-size: $small-title-font-size !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: normal !important;
  }

  &__dropdown-icon {
    font-size: pxToRem(34);
    line-height: 6px;
    color: $link-blue;
    transition: color 0.14s ease-out;
  }

  &__value-container {
    flex-wrap: nowrap !important;

    &::before {
      position: absolute;
      pointer-events: none;
      width: 92%;
      height: 1px;
      content: '';
      background: var(--background, $link-blue);
      left: 4%;
      bottom: 50%;
      transform: translateY(calc(#{$body-font-size * 0.5} + 1.5px));
    }

    &:hover::before {
      background: $text-white;
    }
  }

  &--large {
    .link-select__value-container::before {
      display: none;
    }
  }

  &__indicators {
    font-size: pxToRem(34);
    height: pxToRem(38);
    margin-left: -28px;
  }

  &__control {
    background: transparent !important;
    padding: 0 !important;
    border: none !important;
    outline: transparent !important;
    box-shadow: none !important;
    cursor: pointer !important;
    justify-content: flex-start !important;

    &--is-focused {
      box-shadow: 0 0 0 1px $link-blue !important;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__menu-portal {
    z-index: 100 !important;
  }

  &__menu {
    background: $level-1-bg !important;
    border: 1px solid $level-2-border !important;
    border-radius: 4px !important;
    margin: 0 !important;

    // Let's the menu grow as wide as the widest option label
    // but still be as thin as the selected option label at a minimum
    min-width: 100% !important;
    width: unset !important;
  }

  &__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__option {
    background: $level-1-bg !important;
    font-size: $body-font-size !important;
    font-weight: 400 !important;
    color: $text-white !important;
    cursor: pointer !important;
    transition: color 0.14s ease-out;
    display: table !important;
    width: auto !important;
    white-space: nowrap !important;
    padding: 4px 10px !important;
    min-width: 100%;

    &:hover,
    &--is-focused {
      color: $link-blue !important;
    }
  }

  &__group {
    padding: 0 !important;
  }

  &__group-heading {
    font-weight: 900 !important;
    font-size: pxToRem(15) !important;
    letter-spacing: 0.05em !important;
    padding: 5px 10px !important;
    background: linear-gradient(90deg, #2d2d2d 0%, #333232 100%) !important;
    margin-bottom: 0 !important;
    color: $text-white !important;
    white-space: nowrap;
  }

  &__control:hover {
    .link-select__single-value {
      color: $text-white !important;
    }

    .link-select__dropdown-icon {
      color: $text-white !important;
    }
  }
}

.dropdown-select {
  &__control {
    background: $level-0-bg !important;
    border-color: $level-0-border !important;
    border-width: 1px 0 1px 0 !important;
    border-style: solid !important;
    padding: 0 $gap-8 0 $gap-8 !important;
    height: 64px;
    border-radius: 0 !important;
    transition: border 0.14s ease-out;
    cursor: pointer !important;

    &--is-focused,
    &--menu-is-open {
      box-shadow: none !important;
    }

    &--is-focused {
      border-color: $white-700 !important;
    }

    &--menu-is-open {
      .dropdown-select__btn-wrapper {
        background: $active-400 !important;
      }

      .dropdown-select__btn-icon {
        transform: scaleY(-1);
      }
    }

    &:hover {
      .dropdown-select__value-container {
        filter: brightness(1.15);
      }
    }

    @include md {
      padding: 0 $gap-16 0 $gap-16 !important;
    }
  }

  &__indicators {
    pointer-events: none;
  }

  &__value-container {
    padding: 0 !important;
    overflow-y: visible !important;
    transition: filter 0.14s ease-out;
    height: 100%;
  }

  &__single-value {
    text-transform: uppercase;

    &-subtitle {
      color: $white-600;
      font-weight: 800;
      font-size: $small-font-size;
      letter-spacing: -0.01em;
      line-height: 14px;
    }

    &-title {
      font-weight: 900;
      color: $white-800 !important;
      font-size: $smaller-font-size;
      line-height: 20px;
      letter-spacing: 0.025em;
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__indicator {
    padding: 0px !important;
  }

  &__btn-wrapper {
    display: flex;
    width: 45px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background: $level-1-bg;
    border: 1px solid $level-1-border;
    border-radius: 13px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    transition: background 0.24s ease-out;
  }

  &__btn-icon {
    font-size: 28px !important;
    color: $white-800;
    padding-top: 1px;
    transition: transform 0.32s ease-out;
  }

  &__menu {
    margin: 0px !important;
    background: $level-1-bg !important;
    border: 1px solid $level-1-border !important;
    border-radius: 0px !important;
    border-width: 1px 0px 1px 0px !important;
    box-shadow: 0px 2px 12px #000000;
    z-index: 100 !important;
  }

  &__menu-list {
    padding: 0px !important;
  }

  &__option {
    font-size: $subsubtitle-font-size !important;
    letter-spacing: 0.025em !important;
    color: $white-800 !important;
    padding: $gap-8 $gap-16 !important;
    cursor: pointer !important;

    &--is-focused {
      background-color: $white-500 !important;
    }

    &--is-selected {
      background: $active-400 !important;
    }
  }

  &__group {
    padding: 0px !important;
  }

  &__group-heading {
    font-size: $smaller-font-size !important;
    font-weight: 900 !important;
    letter-spacing: 0.15em !important;
    color: $white-600 !important;
    padding: $gap-4 $gap-16 !important;
    margin-bottom: 0px !important;
  }

  &--compact {
    .dropdown-select {
      &__single-value__subtitle {
        display: none !important;
      }

      &__control {
        height: 41px !important;
      }
    }
  }

  &--level-1 {
    .dropdown-select {
      &__control {
        background: $level-1-bg !important;
        border-color: $level-1-border !important;
      }

      &__menu {
        background: $level-2-bg !important;
        border: 1px solid $level-2-border !important;
      }
    }
  }

  &--border-level-1 {
    .dropdown-select {
      &__control {
        border-color: $level-1-border !important;
      }

      &__menu {
        border: 1px solid $level-2-border !important;
      }
    }
  }

  &--level-2 {
    .dropdown-select {
      &__control {
        background: $level-2-bg !important;
        border-color: $level-2-border !important;
      }

      &__menu {
        background: $level-2-bg !important;
        border: 1px solid $level-2-border !important;
      }
    }
  }

  &--border-level-2 {
    .dropdown-select {
      &__control {
        border-color: $level-2-border !important;
      }

      &__menu {
        border: 1px solid $level-2-border !important;
      }
    }
  }
}

.select {
  &--style-bold {
    [class$="__single-value"],
    [class*="__single-value "] {
      font-weight: 700;
      letter-spacing: -0.02em;
    }
  }
}
