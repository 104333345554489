.horizontal-collapse {
  position: relative;
  transition: width 0.3s ease-in-out;

  &__expander {
    --size: 28px;
    position: absolute;
    top: calc(50% - var(--size) / 2);
    width: calc(var(--size) - 1px);
    height: calc(var(--size) - 1px);
    border-radius: 100%;
    background: $level-1-bg;
    border: 1px solid $level-1-border;
    color: $white-800;
    font-size: calc(var(--size) * 0.8);
    filter: brightness(1);
    transition: filter 0.1s ease-in-out, opacity 0.1s ease-in-out, transform 0.2s ease-in-out;
    z-index: 1;
    opacity: 0;

    i {
      margin-right: 2px;
    }

    &--show {
      opacity: 1;
    }

    &--left {
      left: calc(-1 * var(--size) / 3);
      transform: rotate(180deg);
    }

    &--left-collapsed {
      transform: rotate(0);
    }

    &--right {
      right: calc(-1 * var(--size) / 3);
      transform: rotate(0);
    }

    &--right-collapsed {
      transform: rotate(180deg);
    }

    &:hover {
      cursor: pointer;
      filter: brightness(1.15);
      opacity: 1;
    }
  }
}
