.builds-rotation-facets-section {
  &__select-container {
    width: 100%;

    @include md {
      border: 1px solid $level-1-border;
      border-radius: $border-radius;
      background: $level-1-bg;
    }
  }

  &__desktop-select {
    display: none !important;
    border-bottom: none !important;

    @include md {
      display: flex !important;
    }
  }

  &__mobile-select {
    display: flex !important;
    border-bottom: none !important;

    @include md {
      display: none !important;
    }
  }
}
