.builds-throughput-section {
  .react-table {
    &__cell {
      &--amountPercent {
        border-right: none;
      }
      &--amountBar {
        border-left: none;
        border-right: none;
      }
      &--amountTotal {
        border-left: none;
      }
    }
  }
}
