.heading {
  // Reset browser styles
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;

  position: relative;
  display: block;
  line-height: $body-line-height;

  scroll-margin-top: calc(1rem + $header-mobile-height);
  @include md {
    scroll-margin-top: calc(1rem + var(--header-desktop-height));
  }
}

@mixin heading($style, $levels...) {
  @if length($levels) == 0 {
      $levels: 1, 2, 3, 4, 5, 6;
  }
  @each $level in $levels {
    .heading--style-#{$style}.heading--level-#{$level} {
      @content;
    }
  }
}

// V1
@include heading(v1) {
  margin-top: 1.3em;
  margin-bottom: 0.6em;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include heading(v1, 1) {
  font-size: pxToRem(34);
  font-weight: normal;
  color: $light-blue;
}

@include heading(v1, 2, 3, 4, 5, 6) {
  font-weight: bold;
  color: $light-purple;
}

@include heading(v1, 2) {
  font-size: pxToRem(22);
}

@include heading(v1, 3) {
  font-size: pxToRem(20);
}

@include heading(v1, 4) {
  font-size: pxToRem(17);
}

@include heading(v1, 5) {
  font-size: pxToRem(14);
}

@include heading(v1, 6) {
  font-size: pxToRem(11);
}

// V2 - FIXME: The same as article for now, but to be tweaked to fit in better with the non-article pages
@include heading(v2) {
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include heading(v2, 1, 2, 3) {
  color: $faded-white-1;
}

@include heading(v2, 1) {
  font-size: pxToRem(28);
  margin-top: 1.7em;
  margin-bottom: 1em;
}

@include heading(v2, 2) {
  font-size: pxToRem(24);
  margin-top: 1.7em;
  margin-bottom: 1em;
}

@include heading(v2, 3) {
  font-size: pxToRem(18);
  margin-top: 1.5em;
  margin-bottom: 0.8em;
}

@include heading(v2, 4, 5, 6) {
  color: $faded-white-2;
  text-transform: uppercase;
  margin-top: 1.3em;
  margin-bottom: 0.6em;
}

@include heading(v2, 4) {
  font-size: pxToRem(14);
}

@include heading(v2, 5) {
  font-size: pxToRem(13);
}

@include heading(v2, 6) {
  font-size: pxToRem(12);
}

// Article - in sync with EnhancedMarkdown.scss
@include heading(article) {
  font-weight: bold;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.15em;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $faded-white-1;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    transform: scaleX(0);
    transform-origin: center;
  }

  &.heading--underline::after {
    opacity: 1;
    transform: scaleX(1);
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include heading(article, 1, 2, 3) {
  color: $faded-white-1;
}

@include heading(article, 1) {
  font-size: pxToRem(28);
  margin-top: 1.7em;
  margin-bottom: 1em;
}

@include heading(article, 2) {
  font-size: pxToRem(24);
  margin-top: 1.7em;
  margin-bottom: 1em;
}

@include heading(article, 3) {
  font-size: pxToRem(18);
  margin-top: 1.5em;
  margin-bottom: 0.8em;
}

@include heading(article, 4, 5, 6) {
  color: $faded-white-2;
  text-transform: uppercase;
  margin-top: 1.3em;
  margin-bottom: 0.6em;
}

@include heading(article, 4) {
  font-size: pxToRem(14);
}

@include heading(article, 5) {
  font-size: pxToRem(13);
}

@include heading(article, 6) {
  font-size: pxToRem(12);
}

@include heading(v3) {
  font-weight: bold;
  line-height: 115%;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.85);

  &.heading--with-margin {
    margin-top: 1.7em;
    margin-bottom: 1em;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include heading(v3, 1, 2, 3) {
  color: $white-800;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@include heading(v3, 1) {
  font-size: pxToRem(28);

  @include sm {
    font-size: pxToRem(32);
  }
}

@include heading(v3, 2) {
  font-size: pxToRem(24);
  margin-block-end: $small-gap;

  @include sm {
    font-size: pxToRem(28);
  }
}

@include heading(v3, 3) {
  font-size: pxToRem(20);

  @include sm {
    font-size: pxToRem(24);
  }
}

@include heading(v3, 4, 5, 6) {
  color: $white-750;
}

@include heading(v3, 4) {
  font-size: pxToRem(19);
  margin-block-end: $tiny-gap;

  @include sm {
    font-size: pxToRem(21);
  }
}

@include heading(v3, 5) {
  font-size: pxToRem(18);
}

@include heading(v3, 6) {
  font-size: pxToRem(17);
}

@include heading(v3-compact) {
  font-weight: bold;
  line-height: 115%;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.85);

  &.heading--with-margin {
    margin-top: 1.7em;
    margin-bottom: 1em;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@include heading(v3-compact, 1, 2, 3, 4) {
  color: $white-800;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@include heading(v3-compact, 1) {
  font-size: pxToRem(26);

  @include sm {
    font-size: pxToRem(29);
  }
}

@include heading(v3-compact, 2) {
  font-size: pxToRem(22);
  margin-block-end: $small-gap;

  @include sm {
    font-size: pxToRem(26);
  }
}

@include heading(v3-compact, 3) {
  font-size: pxToRem(19);

  @include sm {
    font-size: pxToRem(22);
  }
}

@include heading(v3-compact, 4) {
  font-size: pxToRem(18);
  margin-block-end: $tiny-gap;

  @include sm {
    font-size: pxToRem(20);
  }
}

@include heading(v3-compact, 5, 6) {
  color: $white-750;
}

@include heading(v3-compact, 5) {
  font-size: pxToRem(17);
}

@include heading(v3-compact, 6) {
  font-size: pxToRem(16);
}
