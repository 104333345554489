.textarea {
  @include body-text(true);
  display: flex;
  flex-direction: column;

  > * {
    @include vertical-margin-between($tiny-gap);
  }

  &--full-height {
    height: 100%;
  }

  &__input {
    box-sizing: border-box;
    width: 100%;
    min-height: 25rem;
    @include restyled-scroll-bar;
    background: $level-2-bg !important;
    border: 1px solid $level-1-border;
    color: $white-800 !important;
    padding: $small-gap;
    transition: border 0.1s ease-in-out;

    &--full-height {
      height: 100%;
    }

    &--not-resizeable {
      resize: none;
    }

    &:focus, &:hover {
      border: 1px solid $level-2-border;
    }
  }

  &__characters-remaining {
    text-align: right;
    margin-right: $small-gap;
  }
}
