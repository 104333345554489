.vertical-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--position-left {
    align-items: baseline;
  }

  &--gap-none {
    gap: 0;

    > * {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &--gap-tiny {
    gap: $tiny-gap;
  }

  &--gap-smaller {
    gap: $smaller-gap;
  }

  &--gap-small {
    gap: $small-gap;
  }

  &--gap-medium {
    gap: $medium-gap;
  }

  &--gap-content-padding {
    gap: $content-padding;
  }

  &--gap-large {
    gap: $large-gap;
  }

  &--gap-larger {
    gap: $larger-gap;
  }

  &--gap-xlarge {
    gap: $xlarge-gap;
  }
}

.react-tile__content--narrow {
  // If inside a narrow tile, use a smaller margin
  .vertical-content--gap-content-padding {
    gap: $small-gap;
  }

  // However, if inside a regular tile (such as a SubTile) inside a narrow tile, go back to using regular margin
  .react-tile__content--regular {
    .vertical-content--gap-content-padding {
      gap: $content-padding;
    }
  }
}
