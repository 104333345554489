.gear-icon {
  display: flex;
  gap: $small-gap;
  align-items: center;
  padding: $tiny-gap;

  &--right {
    @include lg {
      flex-direction: row-reverse;
    }
  }

  &--right & {
    &__item-name {
      @include lg() {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__item-meta {
      &__multitype {
        @include lg() {
          flex-direction: row-reverse;
        }
      }

      &__enchant {
        @include lg() {
          display: flex;
          justify-content: flex-end;
        }
      }

      &__stats {
        @include lg() {
          text-align: right;
        }
      }
    }
  }

  &__icon {
    display: none;

    @include sm {
      display: block;
    }

    img.icon__image {
      border-radius: 5px;
    }
  }

  &__item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: $tiny-gap;

    &-name {
      font-size: $subsubtitle-font-size;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: $smaller-gap;

      > a, span {
        text-overflow: ellipsis;
        overflow-x: hidden;
        overflow-y: clip;
        white-space: nowrap;
      }

      &-icon {
        display: block;

        @include sm {
          display: none;
        }
      }
    }

    &--has-meta & {
      &-name {
        line-height: 100%;
      }
    }

    &-sublabel {
      font-size: $smaller-body-font-size;
      font-weight: 500;
      color: $white-700;
    }

    &-meta {
      display: flex;
      flex-direction: column;
      // gap: $tiny-gap;

      font-size: 17px;

      &__stats {
        font-weight: 700;
      }

      &__gems,
      &__enchant,
      &__multitype {
        display: flex;
        flex-direction: row;
        gap: $smaller-gap;
        align-items: center;

        > * {
          display: flex;
        }
      }
    }
  }
}
