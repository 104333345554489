.report-component-from-script {
  height: 100%;
  box-sizing: border-box;

  &__no-result, &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loading {
    border: 1px solid $level-1-border;
  }

  &__no-result {
    text-align: center;
    @include body-text;
  }

  &__result {
    position: relative;
    height: 100%;
  }

  &__duration {
    position: absolute;
    left: $smaller-gap;
    bottom: $smaller-gap;
  }
}
