.builds-advanced-settings {
  label {
    cursor: pointer;
    user-select: none;
  }

  &__whats-this {
    color: $link-blue;    
  }

  .tooltip__trigger {
    display: none;

    @include sm {
      display: initial;
    }
  }

  &__tooltip {
    z-index: 2;
  }
}
