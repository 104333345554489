.builds-section-component {
  &:empty {
    display: none;
  }

  scroll-margin-top: calc(var(--archon-header-mobile-height) + 1rem);

  @include md {
    scroll-margin-top: 150px;
  }
}
