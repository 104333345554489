// Colors
@use "sass:math";

$white: #fff;
$off-white: #ddd;
$lighter-gray: #a0a0a0;
$light-gray: #555555;
$gray: #333;
$slightly-dark-gray: #242424;
$dark-gray: #141414;
$darker-gray: #0b0b0b;
$dark-gray-gradient: linear-gradient(#161616, #0A0A0A);
$dark-gray-gradient-lighter: linear-gradient(#252525, #151515);
$text-white: #e1f2f5;
$text-gray: rgb(174, 187, 192);
$text-dark-gray: rgb(128, 128, 128);
$off-black: #111;
$black: black;
$blue: rgb(32, 81, 149);
$warcraft-logs-title-top-blue: rgb(45, 170, 200);
$warcraft-logs-title-bottom-blue: rgb(0, 140, 195);
$light-blue: rgb(70, 197, 255);
$dark-blue: #002040;
$darker-blue: #001520;
$link-blue: rgb(70, 197, 255);
$link-muted-blue: rgba(140, 200, 255, 0.87);
$wipefest-blue: #2282c5;
$light-purple: rgb(152, 153, 204);
$orange: rgb(177, 84, 3);
$required: orange;
$highlight: rgb(240, 195, 44);
$red: #c23636;
$dark-red: #632828;
$faded-red: #77434329;
$input-background: rgb(225, 242, 245);
$green: #38e238;
$default-green: #008000;
$encounter-name-color: #B4BDFF;
$twitch-purple: #6441A4;

// Game-specific
$common: #ccc;
$uncommon: rgb(30, 255, 0);
$rare: rgb(0, 112, 255);
$epic: rgb(163, 53, 238);
$legendary: rgb(255, 128, 0);
$encounter: #B4BDFF;
$npc: #84BDF8;
$pet: #64BDC8;

// Transparent white colors
$closer-background: rgba(255, 255, 255, 0.05);
$faded-white-1: rgba(255, 255, 255, 0.87);
$faded-white-2: rgba(255, 255, 255, 0.8);
$faded-white-3: rgba(255, 255, 255, 0.7);

// Font Sizes (use rem to scale font-sizes based on browser settings
$small-font-size: math.div(1rem * 12, 16);
$smaller-font-size: math.div(1rem * 14, 16);
$smaller-body-font-size: math.div(1rem * 16, 16);
$body-font-size: math.div(1rem * 17, 16);
$slim-button-font-size: math.div(1rem * 15, 16);
$button-font-size: math.div(1rem * 17, 16);
$subsubtitle-font-size: math.div(1rem * 18, 16);
$subtitle-font-size: math.div(1rem * 20, 16);
$small-title-font-size: math.div(1rem * 24, 16);
$title-font-size: math.div(1rem * 28, 16);
$page-title-font-size: math.div(1rem * 36, 16);

// Font families
$body-font-family: Avenir, sans-serif;
$title-font-family: ralewayregular, Avenir, sans-serif;

// Line Heights
$smaller-line-height: calc(1rem * 22 / 16);
$body-line-height: calc(1rem * 27 / 16);

// Padding / Margin
$content-padding: 1.25rem;
$subtitle-h-padding: 1rem;
$subtitle-v-padding: 0.5rem;
$tiny-gap: 0.25rem;
$smaller-gap: 0.5rem;
$small-gap: 0.75rem;
$medium-gap: 1rem;
$large-gap: 1.5rem;
$larger-gap: 2.25rem;
$xlarge-gap: 3rem;
$border-radius: 0.25rem;
$medium-border-radius: 0.5rem;

// CMS
$cms-background-color: #121212;

// New Design System

$white-900: #FFFFFF;
$white-800: #DEDEDE;
$white-750: #C6C6C6;
$white-700: #B2B2B2;
$white-600: #727272;
$white-500: #393939;

$info: rgb(70, 197, 255);
$warning: #C9B92A;
$error: #C92A2A;
$success: #7AFD8F;

$active-400: linear-gradient(90deg, darken($blue, 10%) 0%, darken($blue, 0%) 100%);
$active-300-color-1: darken($blue, 20%);
$active-300-color-2: darken($blue, 5%);
$active-300: linear-gradient(90deg, $active-300-color-1 0%, $active-300-color-2 100%);
$active-200: linear-gradient(90deg, darken($blue, 25%) 0%, darken($blue, 10%) 100%);

$archon-title-gradient: linear-gradient(90deg, #fe57f7 0%, #4d0eff 100%);

$level-0-bg-accent: linear-gradient(140deg, #0B0B0B 0%, #090808 100%);
$level-0-bg-no-gradient: rgb(6, 6, 6);
$level-0-no-gradient-transparent: rgba(6, 6, 6, 0);
$level-0-bg: linear-gradient(140deg, #060606 0%, #141414 100%);
$level-0-bg-active: linear-gradient(140deg, #0C0C0C 0%, #191919 100%);
$level-1-bg: linear-gradient(90deg, #141414 0%, #1E1E1E 100%);
$level-1-bg-no-gradient: #141414;
$level-1-bg-active: linear-gradient(90deg, #1C1C1C 0%, #2A2A2A 100%);
$level-2-bg: linear-gradient(90deg, #242424 0%, #363636 100%);
$level-2-bg-no-gradient: #242424;
.level-2-bg {
  background: $level-2-bg;
}

$level-0-border-dark: #1C1C1C;
$level-0-border: #242424;
$level-1-border: #242424;
$level-2-border: #404040;
$level-border-active: #3a6bb4;
$level-border-active-200: #163449;

$segoeUi: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji" !important;

@function figmaGapToRem($pixels) {
  @return math.div(1rem * $pixels, 16);
}

$gap-1: figmaGapToRem(1);
$gap-2: figmaGapToRem(2);
$gap-4: figmaGapToRem(4);
$gap-6: figmaGapToRem(6);
$gap-8: figmaGapToRem(8);
$gap-12: figmaGapToRem(12);
$gap-16: figmaGapToRem(16);
$gap-24: figmaGapToRem(24);
$gap-32: figmaGapToRem(32);
$gap-40: figmaGapToRem(40);
$gap-48: figmaGapToRem(48);
$gap-56: figmaGapToRem(56);
$gap-64: figmaGapToRem(64);

$header-top-height: 45px;
$header-bottom-height: 35px;
$header-mobile-height: 56px;

$alliance: rgb(102, 153, 255);
$horde: rgb(224, 41, 41);

$talent-tree-node-default-size: 1rem;

$body-width: 720px;

// Only set if not set already. next.config.mjs sets this in sassOptions->prependData. buildWebpackConfig.js looks for file.additionalData.
$archon: false !default;
@if ($archon) {
  $blue: rgb(89, 32, 149);

  $active-400: linear-gradient(90deg, darken(#9a34c0, 10%) 0%, darken(#9623d9, 10%) 100%);
  $active-300-color-1: #541471;
  $active-300-color-2: #8414a3;
  $active-300: linear-gradient(90deg, $active-300-color-1 0%, $active-300-color-2 100%);
  $active-200: linear-gradient(90deg, #490e7b 0%, #6c0b93 100%);

  $level-border-active: #9a3ab4;
  $level-border-active-200: #401649;
}
